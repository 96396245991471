<template>
  <!-- ... pup視窗-排課時間 ... -->
  <div>
    <div class="pup_schedule_select" style="margin: 0">
      <el-date-picker
        type="month"
        value-format="yyyy-MM"
        :picker-options="currentYear"
        v-model="selectDate"
        format="M月份"
        @change="changeScheduleMonth"
      ></el-date-picker>
    </div>
    <div class="days-of-modal-week">
      <div class="header-day" v-for="(d, k) in weeksList" :key="k">
        {{ d }}
      </div>
    </div>
    <div class="pup_schedule_list" style="padding-top: 0">
      <div class="pup_schedule_list_title">
        <span
          >{{ isDisabled ? "查看" : "選擇"
          }}{{ selectMth }}月可排課時間(14:00-21:00&nbsp;每時段1小時)</span
        >
      </div>
      <div
        class="pup_schedule_time_container"
        style="display: flex; flex-wrap: wrap"
        v-if="
          (scheduleList.length || scheduleListNext.length) && currentMonthDate
        "
      >
        <div
          class="schedule_calander"
          v-for="(line, k) in currentMonthDate"
          :key="k"
        >
          <div
            class="pup_schedule_time calanderCon"
            v-for="days in line"
            :key="days"
          >
            <div class="pup_schedule_time_title">
              <span>{{ days }}日</span>
              <span>
                <el-checkbox
                  :disabled="isDisabled"
                  v-model="selectAll[days - 1]"
                  @change="selectAlltime($event, days - 1)"
                />
                <label for="d01_all">全選</label>
              </span>
            </div>
            <div class="pup_schedule_time_choice_container">
              <div
                class="pup_schedule_time_choice"
                v-for="(times, i) in teacherSelectT"
                :key="times"
              >
                <span>
                  <span>
                    <input
                      type="checkbox"
                      :disabled="isDisabled"
                      v-model="selectTimes[days - 1][i]"
                      @change="selectTimesInterval(days - 1)"
                    />
                  </span>

                  <label for="d01_14-15">{{ `${times}-${times + 1}` }}</label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="pup_form_schedule_btn mostUp"
        @click="beginSetClass"
        v-if="isDisabled"
      >
        開啟排課
      </div>
      <div
        class="pup_form_schedule_btn mostUp"
        @click="savaClassTimes"
        v-if="!isDisabled"
      >
        儲存排課
      </div>
    </div>
  </div>
  <!-- ... pup視窗-排課時間end ... -->
</template>

<script>
import {
  getTeaScheduleList,
  saveTeaScheduleList
}  from "../../api/api"; 
export default {
  props: { scheduleTeaId: "" },
  data() {
    return {
      isDisabled: true,
      selectYear: "",
      selectMth: 0, //排课月
      selectDate: "", //选择排课日期
      selectAll: [], //全选
      selectTimes: {}, //所选时间
      selectAllNext: [], //全选下个月
      currentMonthDays: 0, //當前月的天数
      currentMonthDate: {},
      scheduleList: [], //排课时间
      scheduleListNext: [], //下个月排课时间
      currentYear: {
        disabledDate: (month) => {
          return false;
          // return month.getFullYear() != new Date().getFullYear();
        },
      },
      weeksList: ["日", "一", "二", "三", "四", "五", "六"],
      teacherSelectT: [
        //排課時間段
        14,
        15,
        16,
        17,
        18,
        19,
        20,
      ],
      scheduleConfig:{
        is_current: false,   //當前自然月
        is_next: false,   //當前月下月,
        is_seleted: true, //選擇月
      }
    };
  },
  watch: {
    selectMth(nV) {
      this.currentMonthDate = {};
      this.currentMonthDays = new Date(this.selectYear, nV, 0).getDate();
      let days = new Date(this.selectYear, nV - 1, 1).getDay();
      let rows = Math.ceil((this.currentMonthDays - (7 - days)) / 7) + 1,
        sumDate = 1;
      for (let i = 0; i < rows; i++) {
        let j = i == 0 ? 7 - days : 7,
          currList = [];
        for (let d = 0; d < j; d++) {
          if (sumDate > this.currentMonthDays) break;
          currList.push(sumDate);
          sumDate = sumDate + 1;
        }
        this.$set(this.currentMonthDate, i, currList);
        if (sumDate > this.currentMonthDays) break;
      }
    },
  },
  created(){
    let year = new Date().getFullYear(),
      month = new Date().getMonth();
    this.selectYear = year
    this.selectMth = month + 1;
    this.selectDate = `${year}-${month + 1}`;
    this.getTeaSchelduleData();
  },
  methods: {
     //选择排课月份
    changeScheduleMonth(m) {
      this.isDisabled = true;
      this.scheduleList = [];
      this.selectYear = parseInt(m.split("-")[0]);
      this.selectMth = parseInt(m.split("-")[1]);
      this.getTeaSchelduleData();
    },
    //当天排课全选
    selectAlltime(event, d) {
      let isAll = this.selectAll[d];
      let currDayAll = [];
      for (let i = 0; i < this.teacherSelectT.length; i++) {
        if (isAll) {
          currDayAll[i] = true;
        } else {
          currDayAll[i] = false;
        }
      }
      this.$set(this.selectTimes, d, currDayAll);
    },
    //选择排课时间
    selectTimesInterval(d) {
      let selectBool = this.selectTimes[d].filter((bool) => bool);
      if (selectBool.length == this.selectTimes[d].length) {
        this.selectAll[d] = true;
      } else {
        this.selectAll[d] = false;
      }
    },

    //获取老师某月排课记录
    async getTeaSchelduleData() {
      // let logMonth = parseInt(this.selectDate.split("-")[1]);
      const res = await getTeaScheduleList({
        teacherId: this.scheduleTeaId,
        logMonth: this.selectDate,
      });
      if (res && res.success == 1) {
        this.scheduleList = res.data.Data ? res.data.Data : [];
        this.renderSelectData(res.data.Data);
      }
    },
    //数据转化可渲染
    renderSelectData(dataList) {
      this.selectTimes = {};
      this.selectAll = [];
      for (let i in dataList) {
        let currentDaySchedule = [];
        let classData = dataList[i].classDate;
        let isAll = classData.filter((time) => time.isSchedule);
        if (isAll.length == classData.length) {
          this.selectAll[i] = true;
        } else {
          this.selectAll[i] = false;
        }
        for (let k in classData) {
          currentDaySchedule[k] = classData[k].isSchedule;
        }
        this.$set(this.selectTimes, i, currentDaySchedule);
        console.log('iiii',this.selectTimes)
      }
    },
    //存储排课时间
    savaClassTimes() {
      let month = new Date().getMonth() + 1;
      for (let i in this.selectTimes) {
        for (let t = 0; t < this.selectTimes[i].length; t++) {
          this.scheduleList[i]["classDate"][t]["isSchedule"] = this.selectTimes[
            i
          ][t];
        }
      }
      let y = this.scheduleList[0].year,
        m = this.scheduleList[0].month;
      let scheduleData = { year: y, month: m, Data: this.scheduleList, teacherId: this.scheduleTeaId };
      this.getSaveSchedule(scheduleData);
    },
    //排课接口
    async getSaveSchedule(params) {
      const res = await saveTeaScheduleList(params);
      if (res && res.code == 200) {
        this.isDisabled = true;
        this.$message.success("排課成功");
        this.$emit('setStatus', 0)
      } 
    },
    beginSetClass() {
      this.isDisabled = false;
      let month_current = new Date().getMonth();
      month_current = month_current + 1; //轉化成接口識別的當月數據
      if (this.scheduleConfig.is_next) {
        this.selectMth = month_current + 1;
      } else if (this.scheduleConfig.is_current) {
        this.selectMth = month_current
      }
      this.getTeaScheduleList();
    },
  },
};
</script>
<style>
  @import "../../assets/css/manager_center.css";
.schedule_calander{
  display: flex; 
  width: 100%;
}
.schedule_calander:first-child {
  justify-content: flex-end;
}
.schedule_calander:last-child {
  justify-content: flex-start;
}
.mostUp{
  z-index: 100;
}
</style>