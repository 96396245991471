<template>
  <div>
    <!-- 中間區塊 學生列表 老師列表 -->
    <div class="manager_lesson_infor">
      <ul class="tabs">
        <li
          class="tab_li"
          @click="checkId = 2"
          :class="{ active: checkId == 2 }"
        >
          测试组卷
        </li>
        <li
          class="tab_li"
          @click="checkId = 1"
          :class="{ active: checkId == 1 }"
        >
          老師列表
        </li>
        <li
          class="tab_li"
          @click="checkId = 0"
          :class="{ active: checkId == 0 }"
        >
          學生列表
        </li>
      </ul>
      <div class="manager_lesson_infor_container">
        <!-- 學生列表 -->
        <div id="infor1" v-if="checkId == 0">
          <div class="manager_lesson_infor_title" style="display: flex;justify-content: flex-end">
            <div class="pup_news_search">
              <input
                @keyup.enter="getStuList(1)"
                v-model="studentPages.name"
                type="text"
                placeholder="搜尋名字"
              />
              <img
                @click="getStuList(1)"
                src="../../assets/image/search_icon.png"
                alt="搜尋"
              />
            </div>
          </div>
          <div
            class="manager_list_container"
            v-for="(stu, key) in studentTableData"
            :key="key"
          >
            <div class="manager_list_title">
              <div>姓名</div>
              <div>下次聯絡</div>
              <div>學習狀態</div>
              <div>剩餘點數</div>
            </div>
            <div class="manager_list_infor">
              <div @click="openBaseInfor(1, stu)">
                <span class="list_infor_name">{{ stu.studentName }}</span>
              </div>
              <div>{{ stu.nextContactDate }}</div>
              <div>{{ stu.studentStatus }}</div>
              <div
                v-if="stu.tallyValue"
                :class="{ colorRed: stu.tallyValue < 10 }"
              >
                {{ stu.tallyValue }}
              </div>
              <div v-else>-</div>
            </div>
            <div class="student_list_btn">
              <div class="setting_class_btn" @click="openDialog(1, stu)">
                課程規劃
              </div>
              <!--
              <div class="study_plan_btn" @click="openDialog(2, stu.userId)">
                讀書規劃
              </div>-->
              <div class="contact_report_btn" @click="openDialog(9, stu)">
                上傳成績
              </div>
              <div class="contact_report_btn" @click="openDialog(3, stu)">
                聯絡回報
              </div>
              <a target="_blank">
                <div @click="goStudyDetail(stu)">查看紀錄</div>
              </a>
            </div>
          </div>
          <div class="paginations">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="studentPages.pageNum"
              :page-sizes="pageSizeL"
              :page-size="studentPages.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="studentPages.counts"
            ></el-pagination>
          </div>
        </div>
        <!-- 學生列表 end -->
        <!-- 老師列表 -->
        <div id="infor2" v-if="checkId == 1">
          <div class="manager_lesson_infor_title" style="display: flex;justify-content: flex-end">
            <div class="pup_news_search">
              <input
                @keyup.enter="getTeaTablist(1)"
                v-model="teacherPages.name"
                type="text"
                placeholder="搜尋名字"
              />
              <img
                @click="getTeaTablist(1)"
                src="../../assets/image/search_icon.png"
                alt="搜尋"
              />
            </div>
          </div>
          <div
            class="manager_list_container"
            v-for="(teacher, key) in teacherTableData"
            :key="key"
          >
            <div class="manager_list_title">
              <div>編號</div>
              <div>姓名</div>
              <div>評價星數</div>
              <div>上課時數</div>
            </div>
            <div class="manager_list_infor">
              <div>{{ teacher.codeNumber }}</div>
              <div @click="openBaseInfor(2, teacher)">
                <span class="list_infor_name">{{ teacher.teacherName }}</span>
              </div>
              <div>{{ teacher.avgEffect }}</div>
              <div>{{ teacher.classAmount }}</div>
            </div>
            <div class="teacher_list_subject">
              <div>
                <div class="teacher_list_subject_title">擅長科目</div>
                <div class="teacher_list_subject_infor">
                  {{
                    teacher.curriculumList
                      ? teacher.curriculumList.toString()
                      : null
                  }}
                </div>
              </div>
            </div>
            <div class="teacher_list_btn">
              <div
                class="class_schedule_btn"
                @click="checkTeacherView(2, teacher)"
              >
                可排課時間
              </div>
              <a @click="checkTeacherView(1, teacher)" target="_blank">
                <!-- href="tutor_manager_page_t.html"  -->
                <div>查看上課紀錄</div>
              </a>
            </div>
          </div>
          <div class="paginations">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="teacherPages.pageNum"
              :page-sizes="pageSizeL"
              :page-size="teacherPages.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="teacherPages.counts"
            ></el-pagination>
          </div>
        </div>
        <!-- 老師列表end -->
        <!-- 组卷管理列表 -->
        <div id="infor3" v-if="checkId == 2">
          <!-- <div class="test_nav_table">
            <div
              @click="testNavId = 1"
              :class="{ test_nav_table_active: testNavId == 1 }"
            >
              测试组卷
            </div>
            <div
              @click="testNavId = 2"
              :class="{ test_nav_table_active: testNavId == 2 }"
            >
              测试结果
            </div>
          </div> -->
          <div v-if="testNavId == 1" style="margin-top: 10px">
            <el-button type="error" @click="goCreateTest">開始組卷</el-button>
            <div
              class="manager_list_container"
              v-for="(test, key) in testTableData"
              :key="key"
            >
              <div class="manager_list_title">
                <div>年级</div>
                <div>科目</div>
                <div>版本</div>
                <div>試卷名稱</div>
              </div>
              <div class="manager_list_infor">
                <div>{{ test.class_name }}</div>
                <div>{{ test.subject_name }}</div>
                <div>{{ test.version_name }}</div>
                <div>{{ test.test_name }}</div>
              </div>
            </div>

            <div class="paginations">
              <el-pagination
                :hide-on-single-page="true"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="testPages.pageNum"
                :page-size="testPages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="testPages.count"
              ></el-pagination>
            </div>
          </div>
          <div v-if="testNavId == 2">
            <div
              class="manager_list_container"
              v-for="(test, key) in testResultList"
              :key="key"
            >
              <div class="manager_test_title">
                <div>學生姓名</div>
                <div style="width: 200px">試卷名</div>
                <div>測試題數</div>
                <div>測試得分</div>
                <div>正確題數</div>
                <div>錯誤題數</div>
                <div>弱點分析</div>
              </div>
              <div class="manager_test_infor">
                <div>{{ test.student_name }}</div>
                <div style="width: 200px">{{ test.test_name }}</div>
                <div>{{ test.question_amount }}</div>
                <div>{{ test.test_score }}</div>
                <div>{{ test.right_amount }}</div>
                <div>{{ test.wrong_amount }}</div>
                <div @click="openBaseInfor(3, test)">
                  <span class="list_infor_name">
                    <!-- {{ test.weakness_analysis }} -->
                    查看詳情
                  </span>
                </div>
              </div>
              <div class="student_list_btn">
                <div
                  style="width: 20%; border-radius: 0 0 10px 10px"
                  class="setting_class_btn"
                  @click="openDialog(1, test)"
                >
                  安排上課
                </div>

                <div
                  style="width: 20%; border-radius: 0 0 10px 10px"
                  class="setting_class_btn"
                  @click="openDialog(4, test)"
                >
                  開始組卷
                </div>
                <div
                  style="width: 20%; border-radius: 0 0 10px 10px"
                  class="study_plan_btn"
                  @click="openDialog(2, test)"
                >
                  學習規劃
                </div>
                <div
                  style="width: 20%; border-radius: 0 0 10px 10px"
                  class="contact_report_btn"
                  @click="openDialog(3, test)"
                >
                  聯絡回報
                </div>
                <div
                  style="width: 20%; border-radius: 0 0 10px 10px"
                  class="setting_class_btn"
                  target="_blank"
                  @click="goStudyDetail(test)"
                >
                  查看紀錄
                </div>
              </div>
            </div>
            <div class="paginations">
              <el-pagination
                :hide-on-single-page="true"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="testUserPages.pageNum"
                :page-size="testUserPages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="testUserPages.count"
              ></el-pagination>
            </div>
          </div>
        </div>
        <!-- 组卷管理列表end -->
      </div>
    </div>
    <!-- 中間區塊 學生列表 老師列表end -->

    <!-- ... pup視窗-基本資料 ... -->
    <div class="pup_information" v-if="baseInformation">
      <div class="pup_manager_bg" @click="openBaseInfor(0)"></div>
      <div class="pup_manager_window">
        <div>
          <div v-if="baseInformation == 2" class="pup_manager_container">
            <div class="pup_information_text">
              老師姓名：
              <span>{{ currList.teacherName }}</span>
            </div>
            <div class="pup_information_text">
              最高學歷：
              <span>{{ currList.education }}</span>
            </div>
            <div class="pup_information_text">
              科系：
              <span>{{ currList.major }}</span>
            </div>
            <div class="pup_information_text">
              教學經驗：
              <span>{{ currList.teaching_experience }}</span>
            </div>
          </div>
          <div v-if="baseInformation == 1" class="pup_manager_container">
            <div class="pup_information_text">
              學生姓名：
              <span>{{ currList.studentName }}</span>
            </div>
            <div class="pup_information_text">
              編號姓名：
              <span>{{ currList.studentCode }}</span>
            </div>
            <div class="pup_information_text">
              就讀學校：
              <span>{{ currList.school }}</span>
            </div>
            <div class="pup_information_text">
              入學年度：
              <span>{{ currList.schoolAge }}</span>
            </div>
            <div class="pup_information_text">
              聯絡電話：
              <span>{{ currList.contactMobile }}</span>
            </div>
            <div class="pup_information_text">
              購買內容：
              <span>{{ currList.courseName }}</span>
            </div>
          </div>
          <div v-if="baseInformation == 3">
            <div class="pup_information_text">
              弱點分析：
              <span>{{ currList.weakness_analysis }}</span>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openBaseInfor(0)"
            class="pup_manager_close"
            alt
          />
        </div>
      </div>
    </div>
    <!-- ... pup視窗-基本資料end ... -->
    <!-- ... pup視窗-安排上課 ... -->
    <div
      class="pup_setting_class"
      v-if="studentClassStatus == 1 || studentClassStatus == 4"
    >
      <div
        class="pup_manager_bg"
        style="z-index: 100"
        @click="openDialog(0)"
      ></div>
      <div class="pup_manager_window pup_height_window" style="z-index: 101">
        <div style="padding-bottom: 90px">
          <div class="pup_manager_container">
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">
                {{ studentClassStatus == 1 ? "上課日期" : "測試日期" }}
              </div>
              <div class="pup_manager_input_time modal_date">
                <el-date-picker
                  style="width: 100%"
                  type="date"
                  value-format="yyyy-MM-dd"
                  @change="getFormList"
                  v-model="classOrderForm.scheduleDate"
                />
              </div>
            </div>
            <div v-if="studentClassStatus == 1">
              <div class="pup_manager_input_container">
                <div class="pup_manager_input_title">
                  上課時間
                  <span>*必選</span>
                </div>
                <div class="pup_manager_input_time">
                  <el-date-picker
                    type="date"
                    v-model="classOrderForm.scheduleDate"
                    placeholder="日期"
                  ></el-date-picker>
                </div>
                <div class="setting_time_colon">：</div>
                <div class="pup_manager_input_time">
                  <el-time-select
                    placeholder="時間"
                    @change="getFormList"
                    v-model="classOrderForm.startHour"
                    :picker-options="{
                      start: '14:00',
                      step: '01:00',
                      end: '21:00',
                    }"
                  ></el-time-select>
                </div>
              </div>
              <div class="pup_manager_input_container">
                <div class="pup_manager_input_title">
                  下課時間
                  <span>*必選</span>
                </div>
                <div class="pup_manager_input_time">
                  <el-date-picker
                    type="date"
                    v-model="classOrderForm.scheduleDate"
                    placeholder="日期"
                  ></el-date-picker>
                </div>
                <div class="setting_time_colon">：</div>
                <div class="pup_manager_input_time">
                  <el-time-select
                    placeholder="時間"
                    @change="getFormList"
                    v-model="classOrderForm.endHour"
                    :picker-options="{
                      start: '14:00',
                      step: '01:00',
                      end: '21:00',
                    }"
                  ></el-time-select>
                </div>
              </div>
              <div v-if="checkId == 0">
                <div class="pup_manager_input_container">
                  <div class="pup_manager_input_title">上課年級</div>
                  <div class="pup_manager_input">
                    <select
                      v-model="classOrderForm.grade"
                      @change="getFormList('culum')"
                    >
                      <option
                        v-for="(g, k) in hsdGradeList"
                        :key="k"
                        :value="g"
                      >
                        {{ g }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="pup_manager_input_container">
                  <div class="pup_manager_input_title">上課科目</div>
                  <div class="pup_manager_input">
                    <select
                      @change="getFormList"
                      v-model="classOrderForm.curriculum"
                    >
                      <option
                        v-for="(c, k) in culumList"
                        :key="k"
                        :value="c.curriculum"
                      >
                        {{ c.curriculum }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="pup_manager_input_container">
                  <div class="pup_manager_input_title">上課老師</div>
                  <div class="pup_manager_input">
                    <select v-model="classTeacherId">
                      <option
                        v-for="(t, k) in teacherList"
                        :key="k"
                        :value="t.teacherId"
                      >
                        {{ t.teacherName }}
                      </option>
                    </select>
                  </div>
                  <div v-if="teaListTip">當前時間沒有可選的上课老師</div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openDialog(0)"
            class="pup_manager_close"
            alt
          />
          <div class="pup_manager_window_btn" @click="createClassOrder">
            {{ studentClassStatus == 1 ? "確認開課" : "確認組卷" }}
          </div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-安排上課end ... -->
    <!-- ... pup視窗-讀書規則 ... -->
    <div class="pup_study_plan" v-if="studentClassStatus == 2">
      <div class="pup_manager_bg" @click="openDialog(0)"></div>
      <div class="pup_manager_window">
        <div style="padding-bottom: 80px">
          <div class="pup_manager_container">
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">預定日期</div>
              <div class="pup_manager_input">
                <el-date-picker
                  style="width: 100%"
                  type="date"
                  value-format="yyyy-MM-dd"
                  v-model="stuReadForm.readingDate"
                />
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">讀書內容</div>
              <div class="pup_manager_input_textarea">
                <textarea v-model="stuReadForm.readingPlanContent"></textarea>
              </div>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openDialog(0)"
            class="pup_manager_close"
            alt
          />
          <div class="pup_manager_window_btn" @click="setStuReading">
            確認規劃
          </div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-讀書規則end ... -->
    <!-- ... pup視窗-聯絡回報 ... -->
    <div class="pup_contact_report" v-if="studentClassStatus == 3">
      <div class="pup_manager_bg" @click="openDialog(0)"></div>
      <div class="pup_manager_window">
        <div style="padding-bottom: 80px">
          <div class="pup_manager_container">
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">聯絡日期</div>
              <div class="pup_manager_input">
                <el-date-picker
                  v-model="stuContactForm.contactDate"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  type="date"
                />
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">聯絡人</div>
              <div class="pup_manager_input">
                <input v-model="stuContactForm.interrelation" type="text" />
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">聯絡內容</div>
              <div class="pup_manager_input_textarea">
                <textarea v-model="stuContactForm.contactContent"></textarea>
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">預計下次聯絡日期</div>
              <div class="pup_manager_input">
                <el-date-picker
                  v-model="stuContactForm.nextContactDate"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  type="date"
                />
              </div>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openDialog(0)"
            class="pup_manager_close"
            alt
          />
          <div class="pup_manager_window_btn" @click="saveStuContact">
            確認回報
          </div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-聯絡回報end ... -->
    <!-- ... pup視窗-上傳成績 ... -->
    <div class="pup_contact_report" v-if="studentClassStatus == 9">
      <div class="pup_manager_bg" @click="openDialog(0)"></div>
      <div class="pup_manager_window">
        <div style="padding-bottom: 80px">
          <div class="pup_manager_container">
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">考試日期</div>
              <div class="pup_manager_input">
                <el-date-picker
                  v-model="stuTestForm.scheduleDate"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  type="date"
                />
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">考試科目</div>
              <div class="pup_manager_input">
                <select v-model="stuTestForm.curriculum">
                  <option
                    v-for="(c, k) in culumList"
                    :key="k"
                    :value="c.curriculum"
                  >
                    {{ c.curriculum }}
                  </option>
                </select>
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">考試類別</div>
              <div class="pup_manager_input">
                <select v-model="stuTestForm.testType">
                  <option value="Section">段考</option>
                  <option value="Weekly">周考</option>
                </select>
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">考試分數</div>
              <div class="pup_manager_input">
                <input v-model="stuTestForm.academicRecord" />
              </div>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openDialog(0)"
            class="pup_manager_close"
            alt
          />
          <div class="pup_manager_window_btn" @click="saveStuTest">
            確認上傳
          </div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-上傳成績end ... -->
    <!--教師排課時間-->
    <div class="pup_schedule" v-if="teacherViewId == 2">
      <div class="pup_manager_bg" @click="checkTeacherView(0)"></div>
      <div class="pup_form_schedule">
        <teacherEditSchedule :scheduleTeaId="scheduleTeaId" @setStatus="checkTeacherView" /> 
        <img
          class="pup_manager_close_inside"
          @click="checkTeacherView(0)"
          src="../../assets/image/close_icon_green.png"
        />
      </div>
    </div>
    <!--教師排課end-->
  </div>
</template>
<script>
import teacherEditSchedule from "../childComponent/teacher_edit_schedule.vue";
import { mapState } from "vuex";
import {
  // getStaffBaseInfo,
  // getStaffLoginout,
  // getStaffPasswordmod,
  // getClassByDay,
  getStudentList,
  getAddReading,
  getAddContact,
  getCreateClassOrder,
  getTeacherFilter,
  getHsdCulum,
  getManagerTeaList,
  getTestList,
  getExperienceTest,
  getAddClassOrderFree,
  getStuTestScore,
} from "../../api/api";
import { Message } from "element-ui";
export default {
  data() {
    return {
      testNavId: 1,
      scheduleTeaId: "",
      userId: "",
      isDisabled: true,
      teacherList: [],
      studentPages: { pageNum: 1, pageSize: 5 },
      teacherPages: { pageNum: 1, pageSize: 5 },
      testPages: { pageNum: 1, pageSize: 10, count: 0 },
      testUserPages: { pageNum: 1, pageSize: 10, count: 0 },
      pageSizeL: [5, 10, 15, 20],
      teaListTip: false, //教師列表提示
      checkCreate: false,
      culumList: [],
      currList: {}, //基本资料
      classOrderForm: {
        grade: "",
        curriculum: "",
        scheduleDate: "",
        startHour: "",
        endHour: "",
      },
      classTeacherId: "", //教师id
      baseInformation: 0, //基本资料
      stuReadForm: {},
      stuContactForm: {}, //联络回报
      stuTestForm: {}, //考試成勣
      changeUser: 0,
      userSetting: false,
      selectDate: "",
      teacherBackRemark: "", //退回回報
      teacherViewId: null,
      checkId: 0,
      selectStuReadMth: "", //选择月份
      showTableNumber: "all",
      stuReadingDate: "", //學生讀書日期
      stuDetailRecords: "", //s聯絡內容
      studentDetailStatus: 0,
      studentClassStatus: null,
      currentMonthDate: {}, //排课月周期
      scheduleList:[],//教师排课列表
      teacherTableData: [], //教師列表
      studentTableData: [], //學生列表
      testTableData: [], //组卷列表
      testResultList: [],
      curriculumList: [],
      weeksList: ["日", "一", "二", "三", "四", "五", "六"],
      uploadUrl: "",
      currentYear: {
        disabledDate: (month) => {
          return month.getFullYear() != new Date().getFullYear();
        },
      },
    };
  },
  components: { teacherEditSchedule },
  created() {
    this.getStuList();
    this.getTeaTablist();
    let year = new Date().getFullYear(),
        intM = new Date().getMonth(),
        month = intM + 1;
        // days = new Date().getDate();
    this.selectDate = `${year}-${month}`;
    this.getTestTableList();
    this.getUserTestList();
  },
  computed: {
    ...mapState(["hsdGradeList"]),
  },
  methods: {
    //進入組卷頁
    goCreateTest() {
      let routeData = this.$router.resolve({
        path: "/question",
        query: { type: 'test' },
      });
      window.open(routeData.href, "_blank");
    },
    //測試結果列表
    async getUserTestList() {
      const { pageNum, pageSize } = this.testUserPages;
      const res = await getExperienceTest({ pageNum, pageSize });
      if (res.success == 1) {
        if (res.data.data) {
          this.testResultList = res.data.data;
        }

        this.testUserPages.count = res.data.count;
      }
    },
    //选课测试列表
    async getTestTableList() {
      const { pageNum, pageSize } = this.testPages;
      const res = await getTestList({ pageNum, pageSize });
      if (res.success == 1) {
        this.testTableData = res.data.data;
        this.testPages.count = res.data.count;
      }
    },
    //学生列表
    async getStuList(num) {
      if(num){
        this.studentPages.pageNum = num
      }
      const res = await getStudentList(this.studentPages);
      if (res.code == 200) {
        this.studentTableData = res.data.data;
        this.studentPages.counts = res.data.count;
      }
    },
    //教师列表
    async getTeaTablist(num) {
      if(num){
        this.teacherPages.pageNum = num
      }
      const res = await getManagerTeaList(this.teacherPages);
      if (res && res.code == 200) {
        this.teacherTableData = res.data.data;
        this.teacherPages.counts = res.data.count;
      }
    },
    //打开基本信息
    openBaseInfor(id, baseInfor) {
      this.currList = baseInfor;
      this.baseInformation = id;
    },
    //学生开课
    async createClassOrder() {
      if (this.studentClassStatus == 1) {
        let res = {},
          tData = {};
        if (this.checkId == 0) {
          if (this.classTeacherId) {
            tData.teacherId = this.classTeacherId;
          } else {
            return false;
          }
        }
        for (let lk in this.classOrderForm) {
          if (lk.indexOf("Hour") >= 0) {
            tData[lk] = parseInt(this.classOrderForm[lk]);
          } else {
            tData[lk] = this.classOrderForm[lk];
          }
        }
        let classOrderFn =
          this.checkId == 0 ? getCreateClassOrder : getAddClassOrderFree;
        res = await classOrderFn({
          ...tData,
          userId: this.userId,
        });
        if (res && res.success == 1) {
          Message({
            type: "success",
            message: "開課成功",
          });
          this.studentClassStatus = false;
        }
      } else {
        this.$router.push(
          `question?schedule_date=${this.classOrderForm.scheduleDate}&user_id=${this.userId}`
        );
      }
    },
    //通过年级选择科目
    async selectCurrCulum(grade) {
      const res = await getHsdCulum({grade});
      if (res && res.success == 1) {
        this.culumList = res.data;
      }
    },
    //获取老师列表
    getFormList(label) {
      if (label == "culum") {
        this.culumList = []
        this.selectCurrCulum(this.classOrderForm.grade);
      }
      let tData = {},
        isNoInput = false;
      for (let lk in this.classOrderForm) {
        if (this.classOrderForm[lk]) {
          if (lk.indexOf("Hour") >= 0) {
            tData[lk] = parseInt(this.classOrderForm[lk]);
          } else {
            tData[lk] = this.classOrderForm[lk];
          }
          isNoInput = true;
        } else {
          isNoInput = false;
          return;
        }
      }
      if (isNoInput) {
        this.getTeacherList(tData);
      }
    },
    async getTeacherList(data) {
      const res = await getTeacherFilter(data);
      if (res && res.success == 1) {
        if (res.data) {
          this.teaListTip = false;
          this.teacherList = res.data;
        } else {
          this.teacherList = [];
          this.teaListTip = true;
        }
      }
    },
    //学生读书计划
    async setStuReading() {
      const res = await getAddReading({
        ...this.stuReadForm,
        userId: this.userId,
      });
      if (res && res.code == 200 && res.success == 1) {
        this.studentClassStatus = 0;
      }
    },
    //联络回报
    async saveStuContact() {
      const res = await getAddContact({
        ...this.stuContactForm,
        userId: this.userId,
      });
      if (res && res.code == 200 && res.success == 1) {
        Message({ type:'success', message:'上傳成功' })
        this.studentClassStatus = 0;
      }
    },
    //學習成績
    async saveStuTest() {
      const { academicRecord } = this.stuTestForm;
      const res = await getStuTestScore({
        ...this.stuTestForm,
        academicRecord: parseInt(academicRecord),
        userId: this.userId,
      });
      if (res && res.code == 200 && res.success == 1) {
        Message({ type:'success', message:'上傳成功' })
        this.studentClassStatus = 0;
      }
    },
    openDialog(id, item) {
      if (id == 1) {
        let routeData = this.$router.resolve({
          path: "/managerLeason",
          query: { name: encodeURI(item.studentName), id: item.userId },
        });
        window.open(routeData.href, "_blank");
      } else {
        if (id == 9) {
          this.selectCurrCulum(item.grade);
        }
        this.studentClassStatus = id;
        this.userId = item.userId;
      }
    },
    //教师试图切换
    checkTeacherView(id, item) {
      this.teacherViewId = id;
      if (id == 1) {
        let routeData = this.$router.resolve({
          path: "/managerTeacher",
          query: { name: encodeURI(item.teacherName), id: item.teacherId },
        });
        window.open(routeData.href, "_blank");
      }
      if (id == 2) {
        this.scheduleTeaId = item.teacherId;
        // this.getTeaSchelduleData();
      }
    },
    //分页列表
    handleSizeChange(val) {
      if (this.checkId == 0) {
        this.studentPages.pageSize = val;
        this.getStuList();
      } else if (this.checkId == 1) {
        this.teacherPages.pageSize = val;
        this.getTeaTablist();
      } else {
        this.studentPages.pageSize = val;
        this.getStuList();
      }
    },
    handleCurrentChange(val) {
      if (this.checkId == 0) {
        this.studentPages.pageNum = val;
        this.getStuList();
      } else {
        this.teacherPages.pageNum = val;
        this.getTeaTablist();
      }
    },
    //學生上課詳情
    goStudyDetail(item) {
      let routeData = this.$router.resolve({
        path: "/managerStudent",
        query: { name: encodeURI(item.studentName), id: item.userId },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style>
@import "../../assets/css/manager_center.css";
.paginations {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.pup_height_window {
  top: 10vh;
}
.days-of-modal-week {
  margin: 15px 0;
  background: #018247;
  display: flex;
}
.days-of-modal-week .header-day {
  flex: 1;
  padding: 10px 14px;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  border-right: 3px solid #ffffff;
}
.calanderCon {
  width: calc(14.2857% - 3px);
  margin-right: 3px;
}
.student_list_btn div {
  width: 25%;
}
.manager_test_title {
  margin-top: 20px;
  display: flex;
  background-color: #202020;
}
.manager_test_title div {
  flex: 1;
  color: #ffffff;
  border-right: 1px solid #ffffff;
  padding: 7px 5px;
  text-align: center;
}
.manager_test_infor {
  display: flex;
}
.manager_test_infor div {
  flex: 1;
  height: 60px;
  font-size: 18px;
  color: #202020;
  text-align: center;
  padding: 0px 5px;
}
.pup_manager_input div,
.pup_manager_input select,
.pup_manager_input_time div,
.pup_manager_input_textarea textarea {
  width: 100%;
  height: 100%;
  font-size: 20px;
  border: 0px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.modal_date {
  width: calc(100% - 88px);
}
.widCont {
  width: 100%;
  text-align: center;
}
.colorRed {
  color: red;
}
.test_nav_table {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.test_nav_table div {
  padding: 4px 15px;
}
.test_nav_table div:hover {
  border-bottom: 2px solid #fff703;
}
.test_nav_table_active {
  border-bottom: 2px solid #fff703;
}
.test_nav_table div:first-child {
  margin-right: 12px;
}
</style>